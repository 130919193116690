let coordinates = [];
let currentFrame = 0;
const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

function createAgentes() {
  let numAgentes = (vw * vh) / 44444;
  for (let i = 0; i < numAgentes; i++) {
    let agenteEl = document.createElement("div");
    agenteEl.classList.add("agente");
    agenteEl.dataset.direction = Math.floor(Math.random() * 8);
    document.getElementById("background").appendChild(agenteEl);

    //Randomly position agente with pixels
    agenteEl.style.left = Math.floor(Math.random() * vw) + "px";
    agenteEl.style.top = Math.floor(Math.random() * vh) + "px";
  }
}

function animate() {
  let agentes = document.getElementsByClassName("agente");
  for (let agente of agentes) {
    let direction = agente.dataset.direction;
    agente.style.width = coordinates[direction][currentFrame].w + "px";
    agente.style.height = coordinates[direction][currentFrame].h + "px";
    agente.style.backgroundPosition =
      "-" +
      coordinates[direction][currentFrame].x +
      "px -" +
      coordinates[direction][currentFrame].y +
      "px";
    agente.style.marginLeft = coordinates[direction][currentFrame].xo + "px";
    agente.style.marginTop = coordinates[direction][currentFrame].yo + "px";

    switch (direction) {
      case "0":
        agente.style.left = parseFloat(agente.style.left) + 3 + "px";
        break;
      case "1":
        agente.style.top = parseFloat(agente.style.top) + 2.12 + "px";
        agente.style.left = parseFloat(agente.style.left) + 2.12 + "px";
        break;
      case "2":
        agente.style.top = parseFloat(agente.style.top) + 3 + "px";
        break;
      case "3":
        agente.style.top = parseFloat(agente.style.top) + 2.12 + "px";
        agente.style.left = parseFloat(agente.style.left) - 2.12 + "px";
        break;
      case "4":
        agente.style.left = parseFloat(agente.style.left) - 3 + "px";
        break;
      case "5":
        agente.style.top = parseFloat(agente.style.top) - 2.12 + "px";
        agente.style.left = parseFloat(agente.style.left) - 2.12 + "px";
        break;
      case "6":
        agente.style.top = parseFloat(agente.style.top) - 3 + "px";
        break;
      case "7":
        agente.style.top = parseFloat(agente.style.top) - 2.12 + "px";
        agente.style.left = parseFloat(agente.style.left) + 2.12 + "px";
        break;
    }

    //Reset to opposite side of screen if off screen
    if (parseFloat(agente.style.left) > vw + 50) {
      agente.style.left = -50 + "px";
    } else if (parseFloat(agente.style.left) < -50) {
      agente.style.left = vw + 50 + "px";
    } else if (parseFloat(agente.style.top) > vh + 120) {
      agente.style.top = -120 + "px";
    } else if (parseFloat(agente.style.top) < -120) {
      agente.style.top = vh + 120 + "px";
    }

    //Depth sorting
    agente.style.zIndex = Math.floor(agente.style.top.replace("px", ""));
  }

  if (currentFrame < 14) {
    currentFrame++;
  } else {
    currentFrame = 0;
  }
}

function processTilemap(tilemap) {
  let frames = tilemap.textures[0].frames;

  for (let animationNo = 1; animationNo <= 8; animationNo++) {
    let animationFrames = frames.filter((frame) =>
      frame.filename.startsWith(animationNo + "/")
    );

    coordinates[animationNo - 1] = [];

    for (let frame of animationFrames) {
      let frameNo = frame.filename.replace(animationNo + "/", "").replace(".png", "");
      frameNo = parseInt(frameNo);
      if (isNaN(frameNo)) {
        continue;
      }
      coordinates[animationNo - 1][frameNo - 1] = frame.frame;
      coordinates[animationNo - 1][frameNo - 1].xo = frame.spriteSourceSize.x;
      coordinates[animationNo - 1][frameNo - 1].yo = frame.spriteSourceSize.y;
    }
  }

  createAgentes();
  setInterval(animate, 88);
}

async function loadTilemap() {
  const response = await fetch("../gfx/agente/agente.json");
  const tilemap = await response.json();

  processTilemap(tilemap);
}
loadTilemap();
